<template>
    <v-row>
        <v-col
                cols="12"
                sm="6"
        >
            <v-card
                    class="pa-2"
                    elevation="0"
            >
                <!--v-card-title></1--v-card-title-->
                <h2>{{header}}</h2>
                <v-alert type="error"
                         close-text="Закрыть сообщение"
                         dismissible
                         v-model="alertError.show"
                         @click="navBack()"
                >
                    {{alertError.text}}
                </v-alert>
                <v-alert type="warning"
                         close-text="Закрыть сообщение"
                         dismissible
                         v-model="alertWarning.show"
                         @click="navBack()"
                >
                    {{alertWarning.text}}
                </v-alert>
                <!--v-btn v-if="navBackShow" block
                       color="primary"
                       @click.prevent="navBack()"
                       elevation="10"
                >
                    Обратно
                </v-btn-->
                <v-card
                        class="mt-6 pa-2"
                        elevation="10"
                        outlined
                        :loading="loading"
                >
                    <v-card-actions>
                        <v-container fluid>
                            <v-btn color="primary"
                                   class="mb-6 ml-3"
                                   @click.prevent="onNext()"
                                   :loading="loadingBtn"
                            >
                                Отправитть запрос

                            </v-btn>
                        </v-container>
                    </v-card-actions>

                    <v-card-text>
                        <v-form>
                            <v-container fluid>
                                <v-text-field
                                        v-model="p_url"
                                        label="url"
                                        placeholder="https://111.11.11.11/name"
                                ></v-text-field>
                                <v-textarea
                                        v-model="p_data"
                                        label="data"
                                        placeholder="{ param: 'value', param2: 'value2'}"
                                ></v-textarea>
                            </v-container>
                        </v-form>
                    </v-card-text>
                    <v-card-actions>
                        <v-container fluid>
                            <v-btn color="primary"
                                   class="mb-6 ml-3"
                                   @click.prevent="onNext()"
                                   :loading="loadingBtn"
                            >
                                Отправитть запрос

                            </v-btn>
                        </v-container>
                    </v-card-actions>
                </v-card>
            </v-card>
        </v-col>
        <v-col
                cols="12"
                sm="6"
        >
            <v-card
                    class="pa-2"
                    elevation="0"
            >
                <h2>Выполнено</h2>
                <v-card
                        class="mt-6 pa-2"
                        elevation="0"
                        outlined
                >


                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-title v-text="POST.url"/>
                            <v-list-item-subtitle v-text="POST.data"/>
                        </v-list-item-content>
                    </v-list-item>


                    <v-container fluid>
                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-text v-text="POST.arr"/>
                        </v-list-item-content>
                    </v-list-item>
                    </v-container>

                    <v-list-item
                            v-for="(e,i) in POST.arr"
                            :key="i"
                    >
                        <v-list-item-content>
                            <v-list-item-title v-text="e.PANELCAPTION1"></v-list-item-title>
                            <v-list-item-subtitle v-text="e.PANELCAPTION2"></v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>


                </v-card>
            </v-card>
        </v-col>

    </v-row>


</template>>

<script>
    import posttest from '../tools/posttest'
    import {thisLog} from '@/components/app/js/globals'


    export default {
        name: 'Test',
        data: () => ({
            name: 'Test',
            header: 'Проверка post',
            loading: false,
            loadingBtn: false,
            log: true,
            alertError: {
                show: false,
                text: null
            },
            alertWarning: {
                show: false,
                text: null
            },
            p_url:     'http://78.29.8.156:9003/getmedg',
            p_data:     '{"pay": "1", "det": "0"}',
            POST: {
                url: '',
                data: {},
                arr: [/*
                {
                    SSORT : 1,
                    PANELCAPTION1 : '1', //Upper, т.к. так передается из REST API
                    PANELCAPTION2 : '2'
                }
                */
                ]
            }
        }),
        computed: {
            navBackShow() {
                //console.log('-----',this.alertError.show, this.alertWarning.show)
                return (this.alertError.show || this.alertWarning.show)
            }
        },
        beforeMount() {
            this.loading=false
        },
        methods: {
            async onNext() {
                try {
                    this.loadingBtn = true

                    this.POST.url=this.p_url

                    //this.POST.data = {
                    //    pay: 1,
                    //    det: 0
                    //}
                    this.POST.data=JSON.parse(this.p_data)
                    this.POST.arr=[]
                    this.alertError.show=false
                    this.alertWarning.show=false



                    let res
                    try {
                        thisLog(this,[`POST ${this.POST.url} ----: `, this.POST.data])
                        res = await posttest(this.POST.url, this.POST.data)
                        thisLog(this,[`POST RECEIVED from ${this.POST.url} ----: `, res.data])
                        this.POST.arr=res.data
                        thisLog(this,['---',res,this.POST.arr])
                        if(this.POST.arr.length==0){
                            this.alertWarning.text="Не найдено данных"
                            this.alertWarning.show=true
                            //pushBack(this,{nodata:true,prev:true})
                        }

                    } catch (err) {
                        //console.log('before mount',err)
                        this.alertError.show=true
                        this.alertError.text=err
                        //pushErr(this, err)
                        //pushBack(this,{prev:true})
                        thisLog(this,['-res--',res])
                    }


                    //this.loadingBtn = false
                }catch(e){
                    alert(e)
                }
                this.loadingBtn = false
            }
        }
    }
</script>
